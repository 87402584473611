import { useMemo } from 'react';

import { useSupplementarySubscriptions } from '../services/subscriptions';
import { useFirewallSites } from '../services/firewall';
import { useMonitoringSites } from '../services/monitoring';
import { useBackupSites } from '../services/backups';
import { getLetterScore } from '../services/site-rating';
import {
  getSubscriptionDomains,
  isEmpty,
  normalizeDomainName,
} from '../../common/helpers';
import SubscriptionsList from '../services/subscriptions/subscriptions-list';

const baseObject = () => {
  return {
    domain: '',
    subscriptions: [],
    mssl: {
      tickets: [],
    },
    support: {
      tickets: [],
    },
    ssl: [],
    firewall: {},
    monitoring: {},
    backups: {},
  };
};

const addSubscriptions = (list, subscriptions, ssl, supSubscriptions) => {
  const subs = [];

  subscriptions.forEach((s) => {
    let domainValues = getSubscriptionDomains(s); // is empty for 'New Account'

    // Check for supplementary subscription data
    const supplementSubscription = supSubscriptions?.data?.find(
      (sup) => sup?.uid === s.externalId,
    );

    if (isEmpty(domainValues) && supplementSubscription) {
      // Supplementary subscription exists
      // Products API is update but subscriptions backend is not
      // Let's mutate the subscription to force the updated state from Products API

      domainValues = getSubscriptionDomains(supplementSubscription);
      s.label = domainValues[0];
    }

    domainValues.forEach((name) => {
      subs.push({
        ...baseObject(),
        domain: normalizeDomainName(name),
        subscriptions: [s],
        ssl: ssl
          ? ssl.filter((ss) => ss.subscriptionId === s.subscriptionId)
          : [],
      });
    });
  });

  // Merge subscriptions that have the same domain
  const merged = [];
  subs.forEach((sub) => {
    // Find the index of the domain's subs object in the merged list
    const domainSubsIndex = merged.findIndex((s) => s.domain === sub.domain);

    if (domainSubsIndex >= 0) {
      merged[domainSubsIndex] = {
        ...merged[domainSubsIndex],
        subscriptions: [
          ...merged[domainSubsIndex].subscriptions,
          ...sub.subscriptions,
        ],
        ssl: [...merged[domainSubsIndex].ssl, ...sub.ssl],
      };
    } else {
      merged.push(sub);
    }
  });

  return list.concat(merged);
};

const addFirewallSites = (list, sites) => {
  list = [...list];

  const add = [];
  sites.forEach((s) => {
    const existing = list.findIndex(
      (u) => u.domain === normalizeDomainName(s.domain),
    );

    if (existing !== -1) {
      const existingWaf = list[existing].firewall ?? {};

      list[existing].firewall = {
        ...existingWaf,
        ...s,
      };
      return;
    }

    add.push({
      ...baseObject(),
      domain: normalizeDomainName(s.domain),
      firewall: { ...s },
    });
  });

  return list.concat(add);
};

const addBackupsSites = (list, sites) => {
  list = [...list];
  const regex = /(^\w+:|^)\/\//;
  sites.forEach((backup) => {
    // Sucuri's endpoint
    let domain = backup.site;

    // MWP's endpoint
    if (backup.url) {
      // Removing the protocol (http://, https:// or //)
      domain = backup.url.replace(regex, '');
      domain =
        domain[domain.length - 1] === '/'
          ? domain.substring(0, domain.length - 1)
          : domain;

      // We should only care about the sites under the sucuri:* partner labels.
      if (
        !backup.partner ||
        (backup.partner && backup.partner.split(':').shift() !== 'sucuri')
      ) {
        return;
      }
    }

    const existing = list.findIndex(
      (u) => u.domain === normalizeDomainName(domain),
    );
    if (existing !== -1) {
      list[existing].backups = backup;
    } else {
      list.push({
        ...baseObject(),
        domain: normalizeDomainName(domain),
        backups: backup,
      });
    }
  });

  return list;
};

const addMonitoringSites = (list, sites) => {
  list = [...list];

  sites.forEach((site) => {
    const existing = list.findIndex(
      (u) => u.domain === normalizeDomainName(site.domain),
    );

    if (existing !== -1) {
      list[existing].monitoring = site;
    } else {
      list.push({
        ...baseObject(),
        domain: normalizeDomainName(site.domain),
        monitoring: site,
      });
    }
  });

  return list;
};

const addMSSL = (list, mssl, subscriptions) => {
  list = [...list];

  mssl.forEach((t) => {
    const subscription = subscriptions.find(
      (s) => s.subscriptionId === t.subscription_id,
    );
    const normalizedDomain = normalizeDomainName(t.domain);

    if (!subscription) {
      return list;
    }

    const existing = list.findIndex(
      (u) =>
        u.domain === normalizedDomain ||
        (normalizedDomain.substring(0, 4) === 'www.' &&
          u.domain === normalizedDomain.substring(4)),
    );

    if (existing !== -1) {
      // make sure the subscription isn't already in the list (to avoid duplicates)
      const subExists = list[existing].subscriptions.find(
        (s) => s && s.subscriptionId === t.subscription_id,
      );
      if (!subExists) {
        list[existing].subscriptions.push(subscription);
      }
      list[existing].mssl.tickets.push(t);
      return;
    }

    list.push({
      ...baseObject(),
      domain: normalizedDomain,
      subscriptions: [subscription],
      mssl: {
        sslOnly: true,
        tickets: [t],
      },
    });
  });

  return list;
};

const addSupportTickets = (unifiedList, supportTickets = []) => {
  return unifiedList.map((u) => {
    const tickets = supportTickets.filter((s) => s.site === u.domain);

    if (tickets.length === 0) {
      return u;
    }

    return {
      ...u,
      support: {
        tickets,
      },
    };
  });
};

const filterJunk = (list) => {
  list = [...list];

  return list.filter((site) => !!site && !!site.domain);
};

const setLetterGrades = (list, loading, error) => {
  list = [...list];

  return list.map((site) => {
    site.letterScore = getLetterScore({ ...site, loading, error });
    return site;
  });
};

/**
 * combines separate lists of subscriptions and MSSL tickets into a unified list,
 * unique by domain. If a WSS subscription and MSSL ticket both share the same
 * domain name, they will be combined to appear as a single item. Items with no
 * discernable domain name (e.g: subscriptions pending setup) will be omitted.
 *
 * @param {array} param0.subscriptions An array of subscriptions
 * @param {array} param0.mssl An array of MSSL tickets
 * @param {array} param0.ssl An array of Standard SSL subscriptions
 * @param {object} param0.firewallSites A map of firewall sites
 * @param {object} param0.monitoringSites A map of monitoring sites
 * @param {object} param0.backupsSites A map of backup sites
 * @returns {{domains: String, subscriptions: Array, mssl: {tickets: Array}}[]}
 *          A combined list of objects with unique domain string properties,
 *          and array properties of MSSL tickets
 */
const unify = ({
  subscriptions = [],
  supSubscriptions = [],
  ssl = [],
  mssl = [],
  supportTickets = [],
  firewallSites = [],
  monitoringSites = {},
  backupsSites = [],
  loading = {},
  error = {},
}) => {
  let unified = [];

  unified = addSubscriptions(unified, subscriptions, ssl, supSubscriptions);
  unified = addFirewallSites(unified, firewallSites);
  unified = addBackupsSites(unified, backupsSites);
  unified = addMonitoringSites(unified, monitoringSites);
  unified = addMSSL(unified, mssl, subscriptions);
  unified = addSupportTickets(unified, supportTickets);
  unified = filterJunk(unified);
  unified = setLetterGrades(unified, loading, error);

  unified.loading = loading;
  unified.error = error;
  return unified;
};

const useUnifiedSites = ({
  subscriptions,
  msslTickets: mssl = {},
  supportTickets = {},
}) => {
  const supSubscriptions = useSupplementarySubscriptions();
  const monitoring = useMonitoringSites();
  const backups = useBackupSites();
  const firewall = useFirewallSites();

  const mergedsubs = () => {
    const mergedSubs = [];
    const hasMuiltSitePlan = supSubscriptions.data.some(
      (sq) => !isEmpty(sq.additionalSites),
    );

    if (!hasMuiltSitePlan) {
      return subscriptions;
    }

    supSubscriptions.data.map((supSub) => {
      if (!isEmpty(supSub.additionalSites)) {
        supSub.additionalSites.map((as) => {
          const sub = {
            ...supSub,
            label: as,
            domain: as,
            externalId: supSub.uid,
            isFakeSubSubscription: true,
            additionalSites: null,
          };

          const parentSub = subscriptions.data.find((s) => {
            if (
              typeof s.externalId === 'string' &&
              typeof supSub.uid === 'string'
            ) {
              return s.externalId.toUpperCase() === supSub.uid.toUpperCase();
            }
          });

          const subIsAlreadyMerged = mergedSubs.some((ms) => {
            return ms.label === sub.label && ms.externalId === sub.externalId;
          });

          if (!isEmpty(parentSub)) {
            sub.subscriptionId = parentSub.subscriptionId;
            sub.product = parentSub.product;
            sub.expiresAt = parentSub.expiresAt;
          }

          if (!subIsAlreadyMerged && !isEmpty(parentSub)) {
            mergedSubs.push(sub);
          }

          /* Merging the parent subscription with the supplementary subscription in the
          case we get conflicting information about who is the Parent subscription of MultiSite */
          if (supSub && parentSub && supSub?.domain !== parentSub?.label) {
            const conflictSub = { ...supSub, ...parentSub };

            const conflictSubIsalreadyMerged = mergedSubs.some((ms) => {
              return (
                ms.label === conflictSub.label &&
                ms.externalId === conflictSub.externalId
              );
            });
            if (!conflictSubIsalreadyMerged) {
              mergedSubs.push(conflictSub);
            }
          }
        });
      }
    });

    return {
      data: new SubscriptionsList(...mergedSubs, ...subscriptions.data),
      loading: subscriptions.loading || supSubscriptions.loading,
      error: subscriptions.error || supSubscriptions.error,
    };
  };

  return useMemo(() => {
    const loading = {
      subscriptions: subscriptions.loading,
      mssl: mssl.loading,
      monitoring: monitoring.loading,
      backups: backups.loading,
      firewall: firewall.loading,
      any:
        subscriptions.loading ||
        mssl.loading ||
        monitoring.loading ||
        backups.loading ||
        firewall.loading,
    };

    const error = {
      subscriptions: subscriptions.error,
      mssl: mssl.error,
      monitoring: monitoring.error,
      backups: backups.error,
      firewall: firewall.error,
      any:
        subscriptions.error ||
        mssl.error ||
        monitoring.error ||
        backups.error ||
        firewall.error,
    };

    const securitySubscriptions = [].concat(
      ...mergedsubs().data.withWebSecurity(),
      ...mergedsubs().data.withBackups(),
      ...mergedsubs().data.withManagedSSL(),
      ...mergedsubs().data.withSSL(),
      ...mergedsubs().data.withUltimateMWP(),
    );

    return unify({
      supSubscriptions,
      subscriptions: securitySubscriptions,
      ssl: subscriptions.data.withSSL(),
      mssl: mssl.data,
      supportTickets: supportTickets.data ?? [],
      firewallSites: firewall.data,
      backupsSites: backups.data,
      monitoringSites: monitoring.data,
      loading,
      error,
    });
  }, [subscriptions, mssl, monitoring, backups, firewall]);
};

export default unify;

export { unify, useUnifiedSites };
