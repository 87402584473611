import Button from '@ux/button';
import Spinner from '@ux/spinner';
import Text from '@ux/text';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  InfoDialog,
  useDynamicFormNavigation,
  useUpdate,
  useDynamicFormData,
  useFormContext,
} from '@secui/support';
import { useCallback, useEffect, useRef, useState } from 'react';

const FTPValidation = () => {
  const { back, next } = useDynamicFormNavigation();
  const intl = useIntl();
  const componentRef = useRef(null);
  const validationStartedRef = useRef(false);
  const data = useDynamicFormData();
  const { setValue } = useFormContext();
  const [isValidConnection, setIsValidConnection] = useState(true);

  const [validateCredentials, validateCredentialsResults] = useUpdate(
    '/api/support/validate-connection-details',
    'POST',
  );

  const validate = useCallback(async () => {
    validationStartedRef.current = true;

    const connectionDetails = {
      type: data.connectionType,
      hostname: data.host,
      port: data.port,
      username: data.userName,
      password: data.password,
      site_directory: data.directory,
    };

    if (data.iHavePrivateKey) {
      connectionDetails.private_key = data.privateKey;
    }

    try {
      const response = await validateCredentials(connectionDetails);
      if (!response.is_valid) {
        return setIsValidConnection(false);
      }
      return next();
    } catch {} // eslint-disable-line no-empty
  }, [validateCredentials, data, next]);

  useEffect(() => {
    if (validationStartedRef.current) {
      return;
    }

    validate();
  }, [validate]);

  const handleSubmitRequestAnywayClick = () => {
    setValue('submitRequestAnyway', true);
    next();
  };

  return (
    <>
      <div ref={componentRef} />
      {validateCredentialsResults.isLoading && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Spinner size="lg" className="mb-3" />
          <FormattedMessage
            id="support__create_ticket__checking_user_name_password"
            tagName={Text.span}
          />
          <FormattedMessage
            id="support__create_ticket__please_wait_few_moments"
            tagName={Text.span}
          />
        </div>
      )}
      {(validateCredentialsResults.isError || !isValidConnection) && (
        <InfoDialog
          primaryAction={
            <Button
              text={intl.formatMessage({ id: 'support__re_enter_credentials' })}
              design="primary"
              onClick={back}
            />
          }
          secondaryAction={
            <Button
              text={intl.formatMessage({
                id: 'support__submit_request_anyway',
              })}
              onClick={handleSubmitRequestAnywayClick}
            />
          }
          onClose={back}
          portalElem={componentRef.current}
        >
          <div>
            <Text.p
              text={intl.formatMessage({
                id: 'support__connection_details__issue_found',
              })}
            />
            <Text.p
              className="font-weight-bold"
              text={intl.formatMessage({
                id: 'support__connection_details__connection_not_standard',
              })}
            />
            <Text.p
              text={intl.formatMessage({
                id: 'support__connection_details__slowdown_warning',
              })}
            />
          </div>
        </InfoDialog>
      )}
    </>
  );
};

export default FTPValidation;
