import { isEmpty } from '../../../common/helpers';
import { Endpoints, ActionList, ActionDelete } from '../../api-data';
import { useData } from '../util.js';
import SubscriptionsList from './subscriptions-list';

const SubscriptionService = {
  listSubscriptions: async () => {
    const { data, loading } = await Endpoints.Subscriptions[ActionList]({});
    if (loading) {
      return [];
    }

    return new SubscriptionsList(...data.subscriptions);
  },
  cancelSubscription: async (subscription) => {
    const { data, loading, error } = await Endpoints.Subscriptions[
      ActionDelete
    ]({ id: subscription.subscriptionId });

    return { data, loading, error };
  },
};

/**
 * A hook to fetch subscriptions
 * @param {array} initialSubscriptions the initial subscriptions list
 * @returns {{data: SubscriptionsList, loading: boolean, error: Error}} data, loading, and error
 */
const useSubscriptions = (initialSubscriptions) => {
  const initialSubscriptionsValue = !isEmpty(initialSubscriptions)
    ? { subscriptions: new SubscriptionsList(...initialSubscriptions) }
    : new SubscriptionsList();

  return useData({
    endpoint: Endpoints.Subscriptions,
    action: ActionList,
    initialValue: initialSubscriptionsValue,
    outputFilter: (output) => {
      if (!output || !output.subscriptions) {
        return new SubscriptionsList();
      }
      return new SubscriptionsList(...output.subscriptions);
    },
    params: {},
  });
};

/**
 * A hook to fetch supplementary subscriptions
 * @returns {{data: SubscriptionsList, loading: boolean, error: Error}} data, loading, and error
 */
const useSupplementarySubscriptions = () => {
  return useData({
    endpoint: Endpoints.SubscriptionsQuery,
    action: ActionList,
    initialValue: new SubscriptionsList(),
    outputFilter: (output) => {
      if (!output || !output.subscriptions) {
        return new SubscriptionsList();
      }

      return new SubscriptionsList(...output.subscriptions);
    },
    params: {},
  });
};

export default SubscriptionService;
export { useSubscriptions, SubscriptionService, useSupplementarySubscriptions };
