import { FormattedMessage, useIntl } from 'react-intl';
import {
  SelectInput,
  TextInput,
  useDynamicFormData,
  useFetch,
} from '@secui/support';
import Spinner from '@ux/spinner';
import Text from '@ux/text';
import Alert from '@ux/alert';
import Button from '@ux/button';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

import tags from './tags';
import { useCreateTicket } from './create-ticket-context';

const technicalExperticeOptions = [
  { label: 'support__tech_expertise__beginner', value: 'tech-beginner' },
  {
    label: 'support__tech_expertise__intermediate',
    value: 'tech-intermediate',
  },
  { label: 'support__tech_expertise__advanced', value: 'tech-advanced' },
  { label: 'support__tech_expertise__no_exp', value: 'tech-no-exp' },
];

const MaxTicketsAllowed = {
  malware: 1,
  waf: 1,
};

const DescribeIssue = () => {
  const intl = useIntl();
  const { issueType, site } = useDynamicFormData();
  const router = useRouter();
  const { domains = [] } = useCreateTicket();

  const { data: activeTickets, isLoading: isSiteMetaFetching } = useFetch(
    '/api/support/tickets/active',
    {
      query: {
        site,
        ticketType: issueType,
      },
      skip: !site,
    },
  );

  const siteOptions = useMemo(() => {
    return domains.map((domain) => {
      return {
        label: domain,
        value: domain,
      };
    });
  }, [domains]);

  const ticketExists = useMemo(() => {
    return activeTickets?.length >= MaxTicketsAllowed[issueType];
  }, [issueType, activeTickets]);

  const handleViewTicketClick = () => {
    router.push(`/support/${activeTickets[0].ticket_id}`);
  };

  return (
    <div className="d-flex flex-column gap-10">
      <SelectInput
        name="site"
        label={intl.formatMessage({
          id: 'support__request_label__which_site_has_problem',
        })}
        options={siteOptions}
        required={true}
      />

      {isSiteMetaFetching && (
        <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5">
          <Spinner size="lg" />
          <FormattedMessage
            id="support__fetching_tickets"
            tagName={Text.span}
          />
        </div>
      )}

      {!isSiteMetaFetching && (
        <>
          {ticketExists && (
            <Alert
              title={intl.formatMessage({
                id: 'support__cleanup_request_exist',
              })}
              id="info-message"
              emphasis="info"
              actions={
                <Button
                  design="inline"
                  text={intl.formatMessage({ id: 'support__view_ticket' })}
                  onClick={handleViewTicketClick}
                />
              }
            />
          )}
          {!ticketExists && (
            <>
              {issueType !== 'malware' && (
                <SelectInput
                  name="technicalExpertice"
                  label={intl.formatMessage({
                    id: 'support__request_label__tech_expertise',
                  })}
                  options={technicalExperticeOptions}
                  required={issueType !== 'malware'}
                  shouldUnRegister
                />
              )}
              <SelectInput
                name="issueTags"
                label={intl.formatMessage({
                  id: 'support__request_label__i_am_having_trouble',
                })}
                type="multiselect"
                options={tags[issueType] ?? []}
                required={true}
              />
              <TextInput
                name="description"
                label={intl.formatMessage({
                  id: 'support__request_label__please_describe_issue',
                })}
                multiline
                size={4}
                helpMessage={intl.formatMessage({
                  id: 'support__help_message__provide_credentials_in_next_step',
                })}
                required={true}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DescribeIssue;
