import { useIntl } from 'react-intl';
import { SelectInput, useFormContext } from '@secui/support';

const productOptions = {
  websec: [
    { label: 'support__issue_type__malware_or_blocklist', value: 'malware' },
    { label: 'support__issue_type__firewall', value: 'waf' },
    { label: 'support__issue_type__monitoring', value: 'monitoring' },
    { label: 'support__issue_type__backups', value: 'backup' },
    { label: 'support__issue_type__other', value: 'other' },
  ],
};

const StartTicket = () => {
  const intl = useIntl();
  const { watch } = useFormContext();
  const productName = watch('product', 'websec');

  return (
    <div className="d-flex flex-column gap-10">
      <SelectInput
        name="product"
        label={intl.formatMessage({
          id: 'support__request_label__which_product_problem',
        })}
        options={[
          // { label: 'support__product__business_email', value: 'business' },
          // { label: 'support__product__cpanel', value: 'cpanel' },
          // { label: 'support__product__managed_wordpress', value: 'wordpress' },
          // { label: 'support__product__my_account', value: 'myAccount' },
          // { label: 'support__product__office_365', value: 'office365' },
          // { label: 'support__product__plesk', value: 'plesk' },
          { label: 'support__product__website_security', value: 'websec' },
        ]}
        required={true}
      />
      <SelectInput
        name="issueType"
        label={intl.formatMessage({
          id: 'support__request_label__what_type_of_issue',
        })}
        options={productOptions[productName]}
        required={true}
      />
    </div>
  );
};

export default StartTicket;
