import { createContext, useContext } from 'react';

export const CreateTicketContext = createContext({});

export const CreateTicketProvider = ({ children, ...rest }) => {
  return (
    <CreateTicketContext.Provider value={rest}>
      {children}
    </CreateTicketContext.Provider>
  );
};
export const useCreateTicket = () => useContext(CreateTicketContext);
