const tags = {
  malware: [
    { label: 'support__issue_tag__mlw_blacklist', value: 'mlw-blacklist' },
    {
      label: 'support__issue_tag__mlw_warning_google',
      value: 'mlw-warning-google',
    },
    {
      label: 'support__issue_tag__mlw_alert_google',
      value: 'mlw-alert-google',
    },
    {
      label: 'support__issue_tag__mlw_alert_googads',
      value: 'mlw-alert-googads',
    },
    {
      label: 'support__issue_tag__mlw_alert_sucuri',
      value: 'mlw-alert-sucuri',
    },
    { label: 'support__issue_tag__mlw_sitecheck', value: 'mlw-sitecheck' },
    { label: 'support__issue_tag__mlw_cc_theft', value: 'mlw-cc-theft' },
    { label: 'support__issue_tag__mlw_emails', value: 'mlw-emails' },
    { label: 'support__issue_tag__mlw_shut_down', value: 'mlw-shut-down' },
    {
      label: 'support__issue_tag__mlw_strange_files',
      value: 'mlw-strange-files',
    },
    { label: 'support__issue_tag__mlw_redirect', value: 'mlw-redirect' },
    { label: 'support__issue_tag__mlw_not_loading', value: 'mlw-not-loading' },
    { label: 'support__issue_tag__mlw_reinfect', value: 'mlw-reinfect' },
    { label: 'support__issue_tag__mlw_ok', value: 'mlw-ok' },
    { label: 'support__issue_tag__mlw_not_working', value: 'mlw-not-working' },
    { label: 'support__issue_tag__mlw_other', value: 'mlw-other' },
  ],
  waf: [
    { label: 'support__issue_tag_waf_help', value: 'waf-help' },
    { label: 'support__issue_tag_waf_blocked', value: 'waf-blocked' },
    { label: 'support__issue_tag_waf_subdomain', value: 'waf-subdomain' },
    { label: 'support__issue_tag_waf_down', value: 'waf-down' },
    { label: 'support__issue_tag_waf_slow', value: 'waf-slow' },
    { label: 'support__issue_tag_waf_ssl', value: 'waf-ssl' },
    { label: 'support__issue_tag_waf_cache', value: 'waf-cache' },
    { label: 'support__issue_tag_waf_disable', value: 'waf-disable' },
    { label: 'support__issue_tag_waf_other', value: 'waf-other' },
  ],
  backup: [
    { label: 'support__issue_tag_bkp_setup', value: 'bkp-setup' },
    { label: 'support__issue_tag_bkp_issue', value: 'bkp-issue' },
    { label: 'support__issue_tag_bkp_other', value: 'bkp-other' },
  ],
  monitoring: [
    { label: 'support__issue_tag_mon_setup', value: 'mon-setup' },
    { label: 'support__issue_tag_mon_issue', value: 'mon-issue' },
    { label: 'support__issue_tag_mon_other', value: 'mon-other' },
  ],
  other: [
    { label: 'support__issue_tag_prd_plg_setup', value: 'prd-plg-setup' },
    { label: 'support__issue_tag_prd_plg_issue', value: 'prd-plg-issue' },
    { label: 'support__issue_tag_prd_other', value: 'prd-other' },
  ],
};

export default tags;
