import Text from '@ux/text';
import { FormattedMessage } from 'react-intl';
import { useRef, useState } from 'react';
import {
  ActionMessage,
  IpAddrDialog,
  PublicKeyDialog,
  useDynamicFormData,
} from '@secui/support';

const SubmitRequest = () => {
  const formData = useDynamicFormData();

  const modalRef = useRef();

  const [showPublicKey, setShowPublicKey] = useState(false);
  const [showIpAddr, setShowIpAddr] = useState(false);

  return (
    <>
      <div ref={modalRef} />
      <PublicKeyDialog
        show={showPublicKey}
        onClose={() => setShowPublicKey(false)}
        portalElem={modalRef.current}
      />
      <IpAddrDialog
        show={showIpAddr}
        onClose={() => setShowIpAddr(false)}
        portalElem={modalRef.current}
      />
      {formData.site && (
        <FormattedMessage
          id="support__submit_ticket__selected_site_confirmation"
          values={{ site: formData.site }}
          tagName={Text.p}
        />
      )}
      <ActionMessage
        id="support__submit_ticket__view_public_key"
        onClick={() => setShowPublicKey(true)}
      />
      <ActionMessage
        id="support__submit_ticket__allow_ip"
        onClick={() => setShowIpAddr(true)}
      />
      <FormattedMessage
        tagName={Text.p}
        id="support__submit_ticket__file_modify_consent"
      />
    </>
  );
};

export default SubmitRequest;
