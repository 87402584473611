import { isEmpty } from '../../common/helpers';
import { warningStates } from './monitoring';
import { isWSSU } from '../../helpers/ApiHelper';

const SCORES = {
  UNKNOWN: {
    text: '',
    value: 0,
  },
  F: {
    text: 'F',
    value: 1,
  },
  C: {
    text: 'C',
    value: 2,
  },
  B: {
    text: 'B',
    value: 3,
  },
  A: {
    text: 'A',
    value: 4,
  },
  A_PLUS: {
    text: 'A+',
    value: 5,
  },
};

/**
 * returns SCORES.A if given an error, the result of a provided evaluator
 * function if any, and SCORES.A_PLUS otherwise
 *
 * @param {object} arg0 An object of option values
 * @param {function} arg0.func a function that may return a score
 * @param  {array} arg0.args an error (maybe), then arguments for func
 * @param  {{text: string, value: number}} arg0.errorScore a SCORE constant to
 * return if arg0.args[0] is an error
 * @returns {{text: string, value: number}} a SCORE constant
 */
const evaluatorFunc = ({ func, args, errorScore }) => {
  const err = args.shift();
  if (err) {
    return errorScore || SCORES.UNKNOWN;
  }

  return func(...args) || SCORES.A_PLUS;
};

const monitoringScore = (monitoring) => {
  const {
    isLastMonitoringScanSuccessful,
    isBlacklisted,
    isInfected,
    isOutdated,
  } = warningStates(monitoring);

  if (isEmpty(monitoring) || !isLastMonitoringScanSuccessful) {
    return SCORES.UNKNOWN;
  }

  if (isBlacklisted || isInfected) {
    return SCORES.F;
  }

  if (isOutdated) {
    return SCORES.B;
  }
};

const backupsScore = (backups, latestBackup) => {
  if (isEmpty(latestBackup)) {
    const activated = !isEmpty(backups);
    const successful =
      (backups && backups.lastBackup && !backups.lastBackup.fail) ||
      backups.backupEnabled;

    if (activated && !successful) {
      return SCORES.B;
    }

    if (!activated) {
      return SCORES.A;
    }
  } else {
    const enabled = backups && backups.backupEnabled && !backups.paused;
    const successful = latestBackup && latestBackup.state === 'completed';

    if (!successful) {
      return SCORES.B;
    }

    if (!enabled) {
      return SCORES.A;
    }
  }
};

const sslScore = (ssl, mssl, subscriptions, inactive) => {
  const sslActivated = !isEmpty(ssl);
  const msslActivated = !isEmpty(mssl);

  if (inactive && inactive.ssl) {
    if (!sslActivated && !msslActivated && !isEmpty(inactive.ssl)) {
      return SCORES.B;
    }
  }

  if (subscriptions) {
    const WSSUSubs = subscriptions.filter((s) => {
      if (s && s.product && s.product.pfid) {
        return isWSSU(s.product.pfid);
      }
    });

    if (WSSUSubs.length > 0) {
      return SCORES.A_PLUS;
    }
  }

  if (!sslActivated && !msslActivated) {
    return SCORES.A;
  }
};

const firewallScore = (firewall) => {
  if (isEmpty(firewall)) {
    return SCORES.A;
  }

  if (!firewall.active) {
    return SCORES.B;
  }
};

const metaScore = ({ monitoring, firewall }) => {
  const { isOutdated } = warningStates(monitoring);
  firewall = isEmpty(firewall) ? { active: false } : firewall;

  if (isOutdated && !firewall.active) {
    return SCORES.C;
  }
};

/**
 *
 * @param {object} monitoring results of monitoring sites API call
 * @param {object} backups results of backups sites API call
 * @param {object} firewall results of firewall sites API call
 * @param {object} loading results of firewall sites API call
 * @param {object} error results of firewall sites API call
 * @returns {string} A letter-grade-like string (A, A+) indicating the security grade
 */
const getLetterScore = (props) => {
  const {
    monitoring,
    backups,
    firewall,
    ssl,
    mssl,
    subscriptions,
    inactive,
    loading = {},
    error = {},
    latestBackup = {},
  } = props;

  if (loading.any) {
    return SCORES.UNKNOWN.text;
  }

  return [
    evaluatorFunc({
      func: monitoringScore,
      args: [error.monitoring, monitoring],
      errorScore: SCORES.UNKNOWN,
    }),
    evaluatorFunc({
      func: backupsScore,
      args: [error.backups, backups, latestBackup],
      errorScore: SCORES.B,
    }),
    evaluatorFunc({
      func: firewallScore,
      args: [error.firewall, firewall],
      errorScore: SCORES.B,
    }),
    evaluatorFunc({
      func: sslScore,
      args: [error.mssl, ssl, mssl, subscriptions, inactive],
      errorScore: SCORES.B,
    }),
    evaluatorFunc({
      func: metaScore,
      args: [error.any, { monitoring, backups, firewall }],
      errorScore: SCORES.B,
    }),
  ].reduce((memo, score) => {
    return memo.value < score.value ? memo : score;
  }).text;
};

export { getLetterScore };
