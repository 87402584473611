import Button from '@ux/button';
import Growl from '@ux/growl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, FTPForm, useUpdate } from '@secui/support';
import { useIntl } from 'react-intl';

export const formValidationSchema = {
  connectionType: yup
    .string()
    .required('support__ftp_form_error__connection_type__required'),
  host: yup.string().required('support__ftp_form_error__host__required'),
  port: yup
    .number()
    .typeError('support__ftp_form_error_port__required')
    .required('support__ftp_form_error_port__required'),
  userName: yup
    .string()
    .required('support__ftp_form_error_user_name__required'),
  password: yup.string().required('support__ftp_form_error_password__required'),
  directory: yup
    .string()
    .required('support__ftp_form_error_directory__required'),
  iHavePrivateKey: yup.bool(),
  privateKey: yup.string().when('iHavePrivateKey', {
    is: true,
    then: () =>
      yup.string().required('support__ftp_form_error_private_key__required'),
    otherwise: () => yup.string(),
  }),
};

const validationSchema = yup.object().shape(formValidationSchema).required();

const EditFTPForm = ({ onClose, connectionDetails = {} }) => {
  const intl = useIntl();

  const [updateCredentials] = useUpdate(
    '/api/support/tickets/{ticketId}/update-connection-details',
    'PATCH',
    {
      params: {
        ticketId: 123,
      },
    },
  );

  const formMethods = useForm({
    defaultValues: {
      connectionType: connectionDetails.type,
      host: connectionDetails.hostname,
      port: connectionDetails.port,
      userName: '',
      password: '',
      directory: connectionDetails.site_directory,
      iHavePrivateKey: false,
      privateKey: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = async (data) => {
    const payload = {
      type: data.connectionType,
      hostname: data.host,
      port: data.port,
      username: data.userName,
      password: data.password,
      site_directory: data.directory,
      private_key: data.privateKey,
    };

    try {
      await updateCredentials(payload);
      Growl.addGrowlMessage({
        id: 'edit-ftp-success',
        title: intl.formatMessage({ id: 'common__success' }),
        emphasis: 'success',
        children: intl.formatMessage({
          id: 'support__update_connection_details_success',
        }),
      });
      onClose();
    } catch {
      Growl.addGrowlMessage({
        id: 'edit-ftp-fail',
        title: intl.formatMessage({ id: 'common__failed' }),
        emphasis: 'critical',
        children: intl.formatMessage({
          id: 'support__update_connection_details_failed',
        }),
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(handleFormSubmit)}
        style={{ minWidth: '55rem' }}
      >
        <FTPForm />
        <Button
          type="submit"
          text={intl.formatMessage({ id: 'common__save' })}
          design="primary"
          className="mt-5"
          disabled={formMethods.formState.isSubmitting}
        />
      </form>
    </FormProvider>
  );
};

export default EditFTPForm;
