import { isEmpty } from '../../common/helpers';
import { Endpoints, ActionList } from '../api-data';
import { useData } from './util.js';

class BackupsSitesList extends Array {}

const useBackupSites = () => {
  return useData({
    endpoint: Endpoints.BackupSites,
    action: ActionList,
    initialValue: new BackupsSitesList(),
    outputFilter: (output) => {
      if (isEmpty(output)) {
        return new BackupsSitesList();
      }

      // Sucuri's endpoint
      if (output.results) {
        return new BackupsSitesList(...output.results);
      }

      // MWP's endpoint
      return new BackupsSitesList(...output);
    },
  });
};

const getBackupDates = (siteId) => {
  return useData({
    endpoint: Endpoints.BackupDates,
    action: ActionList,
    params: {
      siteId: siteId,
    },
  });
};

const getLatestBackup = (siteId) => {
  return useData({
    endpoint: Endpoints.LatestBackup,
    action: ActionList,
    params: {
      siteId: siteId,
    },
  });
};

const getBackupSettings = (siteId) => {
  return useData({
    endpoint: Endpoints.BackupSettings,
    action: ActionList,
    params: {
      siteId: siteId,
    },
  });
};

const getRevisionList = (siteId, revisionId, path) => {
  return useData({
    endpoint: Endpoints.BackupRevision,
    action: ActionList,
    params: {
      siteId: siteId,
      revisionId: revisionId,
      filePath: path,
    },
    initialValue: [],
  });
};

const getTablesList = (siteId, revisionId) => {
  return useData({
    endpoint: Endpoints.BackupTables,
    action: ActionList,
    params: {
      siteId: siteId,
      revisionId: revisionId,
    },
  });
};

const useBackupServer = () => {
  return useData({
    endpoint: Endpoints.BackupSites,
    action: ActionList,
    initialValue: '',
    outputFilter: (output) => {
      return output && output.server ? output.server : '';
    },
  });
};

export {
  useBackupSites,
  useBackupServer,
  getBackupDates,
  getLatestBackup,
  getBackupSettings,
  getRevisionList,
  getTablesList,
  BackupsSitesList,
};
