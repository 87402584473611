import * as yup from 'yup';
import {
  FullScreenTakeover,
  DynamicForm,
  FTPForm,
  useUpdate,
  createDynamicFormSchema,
} from '@secui/support';
import { useIntl } from 'react-intl';
import Growl from '@ux/growl';

import StartTicket from './forms/start';
import DescribeIssue from './forms/describe-issue';
import { formValidationSchema as ftpFormValidationSchema } from '../ftp/edit-ftp';
import FTPValidation from './forms/ftp-validation';
import SubmitRequest from './submit-request';
import { CreateTicketProvider } from './forms/create-ticket-context';

const formSchema = createDynamicFormSchema([
  {
    id: 1,
    title: 'support__request_title__start',
    children: <StartTicket />,
    defaultValues: {
      product: 'websec',
    },
    validationSchema: yup.object({
      product: yup
        .string()
        .required('support__create_ticket__product_name__required'),
      issueType: yup
        .string()
        .required('support__create_ticket__issue_type__required'),
    }),
    onSubmit: {
      when: (data) => data.product === 'websec',
      then: 2,
      otherwise: 5,
    },
  },
  {
    // Malware or blocklist issue description Step
    id: 2,
    title: 'support__request_title__describe_issue',
    children: <DescribeIssue />,
    validationSchema: (data) =>
      yup.object({
        site: yup.string().required('support__create_ticket__site__required'),
        technicalExpertice: yup.string().when([], {
          is: () => data.issueType !== 'malware',
          then: (schema) =>
            schema.required('support__create_ticket__tech_expertise__required'),
        }),
        issueTags: yup
          .array()
          .required('support__create_ticket__site_issue_type__required'),
        description: yup
          .string()
          .required('support__create_ticket__description__required'),
      }),
    onSubmit: 3,
  },
  {
    id: 3,
    title: 'support__request_title__enter_connection_settings',
    children: <FTPForm />,
    onSubmit: 4,
    validationSchema: yup.object(ftpFormValidationSchema),
  },
  {
    // ftp credentials validation stage
    id: 4,
    children: <FTPValidation />,
  },
  {
    // submit step
    id: 5,
    title: 'support__request_title__submit',
    children: <SubmitRequest />,
  },
]);

const CreateTicketForm = ({ onClose, defaultValues = {} }) => {
  const [createTicket] = useUpdate('/api/support/tickets', 'POST');
  const intl = useIntl();

  const handleSubmit = async (data) => {
    const payload = {
      product: data.product,
      ticket_type: data.issueType,
      description: data.description,
      site: data.site,
      tags: data.issueTags,
      connection_details: {
        type: data.connectionType,
        hostname: data.host,
        port: data.port,
        username: data.userName,
        password: data.password,
        site_directory: data.directory,
      },
      tech_expertise: data.technicalExpertice,
    };

    if (data.submitRequestAnyway) {
      payload.skip_validation = true;
    }

    try {
      await createTicket(payload);
      Growl.addGrowlMessage({
        emphasis: 'success',
        title: intl.formatMessage({ id: 'support__ticket_created' }),
        id: 'ticket-create',
        children: intl.formatMessage({
          id: 'support__view_ticket_in_details_page',
        }),
        lifetime: 3000,
      });
      onClose();
    } catch {
      Growl.addGrowlMessage({
        emphasis: 'critical',
        title: intl.formatMessage({ id: 'common__oops' }),
        id: 'ticket-create',
        children: intl.formatMessage({ id: 'support__ticket_create_failed' }),
      });
    }
  };

  return (
    <DynamicForm
      defaultValues={defaultValues}
      schema={formSchema}
      onSubmit={handleSubmit}
    />
  );
};

const CreateTicket = ({ show = false, onClose, domains, defaultValues }) => {
  const handleClose = () => {
    onClose();
  };

  if (!show) {
    return null;
  }

  return (
    <CreateTicketProvider domains={domains}>
      <FullScreenTakeover onClose={handleClose} stretch>
        <CreateTicketForm onClose={handleClose} defaultValues={defaultValues} />
      </FullScreenTakeover>
    </CreateTicketProvider>
  );
};

export default CreateTicket;
